<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
        <b-col xs="12" sm="12" md="6">
          <ValidationProvider name="Service Name" vid="service_id" rules="required|min:1">
              <b-form-group
                label-for="service_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('lrcpnConfig.service_name')}}
              </template>
              <b-form-select
                plain
                v-model="form.service_id"
                :options="paymentServiceList"
                id="service_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col sm="6">
          <ValidationProvider name="Amount" vid="amount" rules="required">
              <b-form-group
              slot-scope="{ valid, errors }"
              label-for="amount"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.amount') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                  v-model="form.amount"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col sm="6">
          <ValidationProvider name="VAT" vid="vat" rules="required|max_value:100">
              <b-form-group
              slot-scope="{ valid, errors }"
              label-for="vat"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.vat') }} (%)<span class="text-danger">*</span>
                  </template>
                  <b-form-input
                  v-model="form.vat"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col sm="6">
          <ValidationProvider name="TAX" vid="tax" rules="required">
              <b-form-group
              slot-scope="{ valid, errors }"
              label-for="tax"
              >
                  <template v-slot:label>
                      {{ $t('globalTrans.tax') }} (%)<span class="text-danger">*</span>
                  </template>
                  <b-form-input
                  v-model="form.tax"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  ></b-form-input>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
        </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { paymentInfoStore, paymentInfoUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      form: {
        service_id: 0,
        amount: 0,
        vat: 0,
        tax: 0
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditEntry()
      this.form = tmp
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.changeEnBn()
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    componentOrgList () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    paymentServiceList () {
      return this.$store.state.commonObj.lrcpnPaymentServiceList
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    changeEnBn () {
        this.paymentServiceList.map(item => {
            if (this.$i18n.locale === 'bn') {
              item.text = item.text_bn
            } else {
              item.text = item.text_en
            }
        })
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(lrcpnServiceBaseUrl, `${paymentInfoUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(lrcpnServiceBaseUrl, paymentInfoStore, this.form)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('LrcpnService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditEntry () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
